//Media queries
$mq-mobile-xs: 320px;
$mq-mobile-s: 768px;
$mq-desktop-md: 1024px;
$mq-desktop-l: 1366px;
$mq-desktop-xl: 1920px;

//grid customisation
$grid-breakpoints: (
  xs: 0,
  sm: 525px,
  md: $mq-desktop-md,
  lg: $mq-desktop-l,
  xl: $mq-desktop-xl,
);

$container-max-widths: (
  sm: 540px,
  /* max 1023*/ md: 720px,
  /* 1024 1365*/ lg: 1175px,
  /* 1366 1919*/ xl: 1300px,
  /* 1920*/
);

/* stylelint-disable color-no-hex */
//colors
$light: #fff;
$dark: #000;
$dark-1: #161615;
$dark-2: #00000029;
$dark-3: #0000005f;
$dark-4: #151515;
$dark-5: #2b2e34;
$dark-6: #00000080;
$dark-7: #242424;
$dark-8: #495057;
$dark-9: rgb(0 0 0 / 30%);
$transparent: rgb(250 250 250 / 0%);

$red: #d9000d;
$dark-red: #8d0008;
$blue: #0055a0;
$blue-2: #6e7ebc;
$blue-3: #0087ff;
$blue-4: #0080c6;
$blue-5: #80bdff;
$red-1: #ed3833;
$red-2: #f6b7b5;
$red-3: #f7cccf;
$red-4: #dc3545;
$red-5: #e4606d;
$brown-light: #830008;
$brown-light-2: #dab2b4;
$brown: #4a0b0f;
$brown-1: #650c22;
$brown-2: #876365;

$gray-light: #f2f2f2;
$gray-light-2: #e8e8e8;
$gray-light-3: #f4f4f4;
$gray-light-3-1: #f5f5f4;
$gray-light-4: #f7f7f7;
$gray-light-5: #e9ecef;
$gray-light-6: #e0e4fb;
$gray-light-7: #ebebeb;
$gray-light-8: #f3f3f3;
$gray-light-9: #f8f8f8;
$gray-light-10: #ababab;
$gray-light-11: #d4c2c2;
$gray-light-12: #efefef;
$gray-light-13: #dee2e6;
$gray-light-14: #ced4da;
$gray-light-15: #ede7e7;
$gray-light-16: #e3e4e4;

$gray-medium: #d6d6d6;
$gray-medium-2: #c2c2c2;
$gray-medium-3: #b5b5b5;
$gray-medium-4: #e2e2e2;
$gray-medium-5: #adb3bc;
$gray-medium-6: rgb(25 25 25 / 38%);
$gray-medium-8: #444;
$gray-medium-9: rgb(235 235 235 / 50%);
$gray-medium-10: #c4c4c4;
$gray-medium-11: #c9b9ba;
$gray-medium-12: #575757;
$gray-medium-13: #6c757d;
$gray-medium-14: #ddd1d1;
$gray-medium-15: #808384;

$gray-dark: #929292;
$gray-dark-2: #707070;
$gray-dark-3: #676a6f;
$gray-dark-4: #313131;
$gray-dark-5: #d8d8d8;
$gray-dark-6: #212529;
$gray-dark-7: #1e2529;
$gray-dark-8: #5c5c5b;
$gray-dark-9: #bcbcbc;

$gray-shaddow: #e0e0e0;
$gray-shaddow-2: rgb(0 0 0 / 16%);
$gray-shaddow-3: rgba(0 0 0 / 29%);
$gray-shaddow-4: rgba(0 0 0 / 50%);

$green: #0eb266;
$green-1: #56ba49;
$green-2: #56c374;
$green-3: #369750;
$green-4: #00a651;
$green-5: #40ab00;
$green-6: rgba(111 179 70 / 22%);
$green-7: #28a745;
$green-8: #34ce57;
$green-9: rgba(86 195 116 / 38%);
$green-10: #118030;
$green-light: #cff0e0;
$green-light-1: #bce9d4;
$green-light-2: #daf2e0;
$green-dark: #075d35;

$yellow: #c1d841;
$yellow-1: #f5d629;
$yellow-2: #f7b944;
$gold: #f6b933;

$orange: #f06e38;
$purple: #894b9e;

/* stylelint-enable color-no-hex */

$theme-colors: (
  primary: $red,
  light: $gray-light-2,
);

// fonts
$lato: 'Lato', 'RUBIK', sans-serif;
